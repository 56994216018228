<template>
  <div>
    <v-dialog
      v-model="multipleOrderDialog"
      @click:outside="$emit('update:multipleOrderDialog', false);"
      max-width="70%"
    >
      <v-card>
        <v-card-title class="pa-2">
          <v-spacer />
          <v-btn
            color="primary"
            small
            :disabled="!valid"
            :loading="loading"
            @click="prepareInvoice"
          >
            {{$t('invoice.prepareInvoice')}}
          </v-btn>
          <v-btn
            icon
            @click="$emit('update:multipleOrderDialog', false);"
          >
            <v-icon color="primary darken-1">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-h5 px-4">
          <!-- <FormSkeleton v-if="contactId && Object.keys(contactById).length <= 0"/> -->
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <Alert :alert.sync="alert" :message="message" />
            <v-row>
              <v-col cols="12">
                <v-card
                  class="mx-auto"
                  flat
                >
                  <v-card-title class="py-1 primary--text">
                    <v-icon color="primary">mdi-account-group-outline</v-icon>
                    <h6 class="ms-2 font-weight-regular">{{$t('invoice.customerDetail')}}</h6>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row :dense="true">
                      <v-col cols="4">
                        <v-autocomplete
                          class="asterisk"
                          autocomplete="new-password"
                          dense
                          cache-items
                          v-model="selectedCustomer"
                          :items="customerList.data"
                          item-text="name_1"
                          item-value="id"
                          @change="onCustomerChange"
                          flat
                          :rules="customerValid"
                          return-object
                          hide-no-data
                          hide-details="auto"
                          required
                          single-line
                          @dblclick="customerDialog = true"
                          :search-input.sync="customerSearch"
                          :label="$t('invoice.field.customer')"
                        >
                          <template v-slot:selection="data">
                            {{ `${data.item.id}. ${data.item.name_1}` }}
                          </template>
                          <template v-slot:item="data">
                            <div class="font-weight-medium" style="font-size: .8125rem;display: flex;align-item: center;">
                              {{ `${data.item.id}. ${data.item.name_1}` }}
                            </div>
                          </template>
                          <template v-slot:append-item>
                            <div v-show="hasNextPage" v-intersect="infiniteScroll" ref="load" class="loader text-center">
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              />
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          dense
                          hide-details="auto"
                          v-model="model.address"
                          :label="$t('invoice.field.address')"
                          :rules="[lessThanValidation($t('invoice.field.address'), $t('common.less'), 250, model.address)]"
                          required
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          dense
                          hide-details="auto"
                          v-model="model.town"
                          :label="$t('invoice.field.town')"
                          :rules="[lessThanValidation($t('invoice.field.town'), $t('common.less'), 100, model.town)]"
                          required
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card
                  class="mx-auto"
                  flat
                >
                  <v-card-title class="py-1 primary--text">
                    <v-icon color="primary">mdi-cart-plus</v-icon>
                    <h6 class="ms-2 align-self-center font-weight-regular">{{$t('invoice.listOfItems')}}</h6>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row :dense="true">
                      <v-col cols="12">
                        <v-simple-table
                          dense
                          class="custom-table elevation-1 item-table"
                          fixed-header
                          height="250"
                        >
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th v-if="invoiceItems.length > 0" :style="{width: '20px'}">
                                  <v-checkbox @click="selectAllOrder" v-model="allSelectedOrder" x-small dense hide-details />
                                </th>
                                <th :style="{width: '40px'}">
                                  {{ $t('invoice.no') }}
                                </th>
                                <th :style="{width: '140px'}">
                                  {{ $t('invoice.product') }}
                                </th>
                                <th :style="{width: '235px'}">
                                  {{ $t('invoice.itemDescription') }}
                                </th>
                                <th :style="{width: '50px'}">
                                  {{ $t('invoice.quantity') }}
                                </th>
                                <th class="text-center" :style="{width: '100px'}">
                                  {{ $t('invoice.unitPrice') }}
                                </th>
                                <th :style="{width: '50px'}">
                                  {{ $t('invoice.lineDiscount') }}
                                </th>
                                <th class="text-center" :style="{width: '110px'}">
                                  {{ $t('invoice.total') }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="invoiceItems.length <= 0">
                                <td colspan="7" class="text-center">{{$t('common.noDataFound')}}</td>
                              </tr>
                              <template v-else v-for="(orderItem, indexItem) in invoiceItems">
                                <tr v-if="orderItem.orderItems.length > 0" class="accent lighten-3" style="vertical-align: bottom" :key="indexItem+1">
                                  <td>
                                    <v-checkbox v-model="selectedOrder" :value="orderItem" @click="selectedInvoiceProduct" @change="checkBoxEventCalculate(orderItem)" dense hide-details />
                                  </td>
                                  <td class="text-center primary--text font-weight-bold" colspan="7">
                                    {{ $t('invoice.field.orderNo') }} {{ orderItem.id }} {{ orderItem.title ? '/ ' + orderItem.title : null }}
                                  </td>
                                </tr>
                                <tr v-for="(item, index) in orderItem.orderItems" style="vertical-align: bottom" :key="orderItem.id+'-'+index">
                                  <td>
                                    <v-checkbox v-model="selectedOrder" :value="orderItem" readonly dense hide-details />
                                  </td>
                                  <td rowspan="1">
                                    {{index+1}}
                                  </td>
                                  <td>
                                    <span>
                                      {{item.item_code}}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {{item.item_description}}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {{item.quantity}}
                                    </span>
                                  </td>
                                  <td>
                                    <span>
                                      {{ numberWithCommas(item.unit_price) }}
                                    </span>
                                  </td>
                                  <td>
                                    <span v-if="item.line_discount">
                                      {{ (item.line_discount).toFixed(2) }}
                                    </span>
                                    <span v-else>
                                      {{ getLineDiscount(orderItem).toFixed(2) }}
                                    </span>
                                  </td>
                                  <td :class="locale === 'he' ? 'text-start' : 'text-end'" v-if="item.line_discount">
                                    {{ numberWithCommas(item.total) }}
                                  </td>
                                  <td :class="locale === 'he' ? 'text-start' : 'text-end'" v-else>
                                    {{ itemTotal(orderItem, item) }}
                                  </td>
                                </tr>
                                <tr class="accent lighten-3" style="vertical-align: middle" :key="indexItem+1">
                                  <td class="text-center primary--text font-weight-bold" colspan="6">
                                    {{ $t('invoice.field.discountPrecentage') }}: {{ orderItem.discount_percentage ? orderItem.discount_percentage : 0 }} 
                                  </td>
                                  <td class="text-center primary--text font-weight-bold" colspan="2">
                                    {{ $t('invoice.total') }}: {{ totalByOrderCalculation(orderItem) }}
                                  </td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                    <v-row :dense="true" class="pt-6">
                      <v-col cols="7">
                      </v-col>
                      <v-divider class="mx-6" vertical/>
                      <v-col cols="4">
                        <v-row dense>
                          <v-col cols="6" class="font-weight-bold">
                            {{$t('invoice.total')}}
                          </v-col>
                          <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            {{numberWithCommas(model.total)}}
                          </v-col>
                        </v-row>
                        <!-- <v-row dense>
                          <v-col cols="6">
                            <v-row dense no-gutters>
                              <v-col cols="6">
                                {{$t('invoice.field.discount')}}
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  readonly
                                  height="18"
                                  class="ma-0 pa-0"
                                  hide-details="auto"
                                  dense
                                  v-model="model.discount_percentage"
                                >
                                  <template v-slot:append>
                                    <v-icon x-small color="primary">mdi-percent-outline</v-icon>
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="6" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            {{numberWithCommas(model.discount)}}
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="6" class="font-weight-bold">
                            {{$t('invoice.field.afterDiscount')}}
                          </v-col>
                          <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            {{numberWithCommas(model.after_discount)}}
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="6">
                            <v-row dense no-gutters>
                              <v-col cols="6">
                                {{$t('invoice.field.vat')}}
                              </v-col>
                              <v-col cols="6">
                                <v-text-field
                                  readonly
                                  height="18"
                                  class="ma-0 pa-0"
                                  hide-details="auto"
                                  dense
                                  v-model="model.vat_percentage"
                                >
                                  <template v-slot:append>
                                    <v-icon x-small color="primary">mdi-percent-outline</v-icon>
                                  </template>
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="6" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            {{numberWithCommas(model.vat)}}
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="6" class="font-weight-bold">
                            {{$t('invoice.field.grandTotal')}}
                          </v-col>
                          <v-col cols="6" class="font-weight-bold" :class="locale === 'he' ? 'text-start' : 'text-end'">
                            {{numberWithCommas(model.grand_total)}}
                          </v-col>
                        </v-row> -->
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Alert from "@/components/Alert"
import { validationMixin } from '@/mixins/validationMixin';
import { intersectionMixin } from '@/mixins/intersectionMixin';
import { searchMixin } from '@/mixins/searchMixin';

export default {
  name: "MultipleOrder",
  props: ["multipleOrderDialog", "isMultiple", "multipleOrderModel"],
  components: {
    Alert
  },
  mixins: [validationMixin, intersectionMixin, searchMixin],
  data() {
    return {
      listSkeleton: false,
      message: '',
      alert: false,
      loading: false,
      valid: false,
      selectedCustomer: null,
      model: {
        total: 0.00,
        discount: 0.00,
        after_discount: 0.00,
        vat: 0.00,
        grand_total: 0.00
      },
      customerDialog: false,
      customerPage: 1,
      customerLimit: 10,
      selectedOrder: [],
      allSelectedOrder: false,
      invoiceItems: [],
      includeVatCharge: false,
    }
  },
  computed: {
    ...mapGetters({
      profileById: 'profile/profileById',
      customerList: 'customer/customerList',
      locale: 'locale',
    }),
  },
  watch: {
    profileById() {
      if (Object.keys(this.profileById).length > 0 && !this.$route.params.invoice_id) {
        this.model.vat_percentage = this.profileById.vat_percentage;
      }
    },
    customerLimit() {
      this.$store.dispatch('customer/GetCustomer', {page: 1, limit: this.customerLimit, order_by: 'name_1|asc'});
    },
    multipleOrderDialog(val) {
      if (!val) {
        this.model = {
          total: 0.00,
          discount: 0.00,
          after_discount: 0.00,
          vat: 0.00,
          grand_total: 0.00
        };
        this.selectedCustomer = null;
        this.selectedOrder = [];
        this.customerDialog = false;
        this.customerSearch = '';
        this.customerPage = 1,
        this.customerLimit = 10;
        this.selectedOrder = [];
        this.allSelectedOrder = false;
        this.invoiceItems = [];
        this.listSkeleton = false;
        this.message = '';
        this.alert = false;
        this.loading = false;
        this.valid = false;
      }
    }
  },
  methods: {
    selectedInvoiceProduct() {
      if (this.selectedOrder.length > 0 && this.invoiceItems.length > 0) {
        let selectedData = [];
        this.invoiceItems.forEach(mainItem => {
          let filterItem = mainItem?.orderItems.filter((item) => !item.line_discount)
          mainItem.orderItems.forEach(item => {
            if (!item.line_discount) {
              item.line_discount = mainItem.discount_percentage / filterItem.length
              item.total = ((item.quantity * item.unit_price) - ((item.quantity * item.unit_price) * (item.line_discount) / 100)).toFixed(2)
            }
            selectedData.push(item);
          });
        })
        if (this.selectedOrder && this.invoiceItems.length == this.selectedOrder.length) {
          this.allSelectedOrder = true;
        } else {
          if (!this.selectedOrder) {
            this.selectedOrder = [];
          }
          this.allSelectedOrder = false;
        }
      }
    },
    checkBoxEventCalculate() {
      if (!this.selectedOrder) {
        this.selectedOrder = [];
      }
      this.invoicePriceCalculation();
    },
    selectAllOrder() {
      this.selectedOrder = [];
      // this.model.total = 0.00;
      if (this.allSelectedOrder) {
        for (let invoice in this.invoiceItems) {
          this.selectedOrder.push(this.invoiceItems[invoice]);
          // this.model.total += parseFloat(this.invoiceItems[invoice].total);
          this.invoicePriceCalculation();
        }
      }
      else {
        this.invoicePriceCalculation();
      }
    },
    getLineDiscount(orderItem) {
      let filterItem = orderItem.orderItems.filter((item) => !item.line_discount)
      return orderItem.discount_percentage / filterItem.length
    },
    itemTotal(orderItem, item) {
      if(!item.line_discount){
        let filterItem = orderItem?.orderItems.filter((item) => !item.line_discount)
        return ((item.quantity * item.unit_price) - ((item.quantity * item.unit_price) * ((orderItem.discount_percentage / filterItem.length) / 100))).toFixed(2)
      } else {
        return item.total
      }
    },
    totalByOrderCalculation(orderItem) {
      return orderItem.orderItems.reduce((acc, cur) => acc + Number(this.itemTotal(orderItem, cur)), 0).toFixed(2)
    },
    invoicePriceCalculation () {
      // totol item's calculation
      if ((!this.invoiceId && this.profileById.invoice && this.includeVatCharge) || (this.invoiceId && this.model.including_vat)) {
      // if (this.profileById.invoice && this.includeVatCharge) {
        this.model.total = this.selectedOrder && this.selectedOrder.length > 0 ? this.selectedOrder.reduce(function(total, item){
          return parseFloat(item.total) ? parseFloat(total) + parseFloat(item.total) : total + 0;
        },0).toFixed(2) / (this.model.vat_percentage / 100 + 1) : 0;
      } else {
        this.model.total = this.selectedOrder && this.selectedOrder.length > 0 ? this.selectedOrder.reduce((acc, cur) => acc + Number(this.totalByOrderCalculation(cur)), 0).toFixed(2) : 0
      }

      // total discount %
      let filterItem = this.selectedOrder?.orderItems?.filter((item) => item.line_discount)
      if (filterItem && filterItem.length) {
        this.model.discount_percentage = this.selectedOrder && this.selectedOrder.length > 0 ? this.selectedOrder.reduce(function(discount_percentage, item){
          return parseFloat(item.discount_percentage) ? parseFloat(discount_percentage) + parseFloat(item.discount_percentage) : discount_percentage + 0;
        },0) : 0;
        // discount amount calculation
        this.model.discount = this.selectedOrder && this.selectedOrder.length > 0 ? this.selectedOrder.reduce(function(discount, item){
          return parseFloat(item.discount) ? parseFloat(discount) + parseFloat(item.discount) : discount + 0;
        },0).toFixed(2) : 0;
      }

      // total amount after discount calculation
      this.model.after_discount = this.selectedOrder && this.selectedOrder.length > 0 ? this.selectedOrder.reduce(function(after_discount, item){
        return parseFloat(item.after_discount) ? parseFloat(after_discount) + parseFloat(item.after_discount) : after_discount + 0;
      },0).toFixed(2) : 0;

      // total vat %
      // this.model.vat_percentage = this.selectedOrder && this.selectedOrder.length > 0 ? this.selectedOrder.reduce(function(vat_percentage, item){
      //   return parseFloat(item.vat_percentage) ? parseFloat(vat_percentage) + parseFloat(item.vat_percentage) : vat_percentage + 0;
      // },0) : 0;

      // total amount after vat calculation
      this.model.vat_percentage = parseFloat(this.model.vat_percentage) ? parseFloat(this.model.vat_percentage) : null;
      this.model.vat = parseFloat(this.model.vat_percentage) && this.includeVatCharge ? (parseFloat(this.model.after_discount) *(parseFloat(this.model.vat_percentage))/100).toFixed(2) : (0).toFixed(2);

      // // grand total amount calculation
      // this.model.grand_total = this.selectedOrder && this.selectedOrder.length > 0 ? this.selectedOrder.reduce(function(grand_total, item){
      //   return parseFloat(item.grand_total) ? parseFloat(grand_total) + parseFloat(item.grand_total) : grand_total + 0;
      // },0).toFixed(2) : 0;
      // grand total amount calculation
      this.model.grand_total = parseFloat(this.model.vat) ? (parseFloat(this.model.after_discount) + parseFloat(this.model.vat)).toFixed(2) : (parseFloat(this.model.after_discount)).toFixed(2);

      // after vat grand total rounding if total rounding is true
      if(this.profileById.total_rounding) {
        // let val = this.model.grand_total;
        // this.model.grand_total = Math.round(this.model.grand_total)
        // let discount = val - this.model.grand_total;
        // if(discount == -0.5){
        //   discount = 0.5;
        //   this.model.grand_total = Math.trunc(val)
        // }
        // this.model.discount =  (parseFloat(this.model.discount) - parseFloat(discount)).toFixed(2)
        // this.model.after_discount = parseFloat(this.model.discount) ? (parseFloat(this.model.total) - parseFloat(this.model.discount)).toFixed(2) : (parseFloat(this.model.total)).toFixed(2);
        let total = this.model.total;
        let discount = this.model.discount;
        let vatPercentage = this.model.vat_percentage;
        let grandTotal = parseInt(this.model.grand_total);
        let roundingDiff = this.model.grand_total - grandTotal;
        let findRoundNumber = parseFloat(roundingDiff/(1+(vatPercentage/100))).toFixed(2);
        if (findRoundNumber <= 0.5) {
          // calculate discount if findRoundNumber is lessthan 0.5
          this.model.discount = (parseFloat(discount) + parseFloat(findRoundNumber)).toFixed(2);
          // calculate after_discount
          this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
          // calculate vat
          this.model.vat = (parseFloat(grandTotal) - parseFloat(this.model.after_discount)).toFixed(2);
          // calculate grand_total
          this.model.grand_total = parseFloat(grandTotal).toFixed(2);
        } else {
          let roundingDiff = parseFloat(((grandTotal + 1)-this.model.grand_total) * (-1)).toFixed(2);
          // calculate discount if findRoundNumber is lessthan 0.5
          this.model.discount = (parseFloat(discount) + (parseFloat(roundingDiff/(1+(vatPercentage/100))))).toFixed(2);
          // calculate after_discount
          this.model.after_discount = (parseFloat(total) - parseFloat(this.model.discount)).toFixed(2);
          // calculate vat
          this.model.vat = ((parseFloat(grandTotal) + 1) - parseFloat(this.model.after_discount)).toFixed(2);
          // calculate grand_total
          this.model.grand_total = (parseFloat(grandTotal) + 1).toFixed(2);
        }
      }
    },
    numberWithCommas(x, type = 'float') {
      return x ? type === 'float' ? parseFloat(x).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : type === 'float' ? (0).toFixed(2) : x;
    },
    async onCustomerChange(customer) {
      if (customer) {
        let customerModel = {};
        this.selectedCustomer = {id: customer.id, name_1: customer?.name_1, fixed_discount: customer?.fixed_discount};
        customerModel.address = customer.address_1;
        customerModel.town = customer.town_1;
        customerModel.authorized_dealer_no = customer.authorized_dealer_no;
        customerModel.email = customer.email;
        customerModel.country = customer.country;
        customerModel.phone_1 = customer.phone_1;
        customerModel.phone_2 = customer.phone_2;
        customerModel.cellular = customer.cellular_1;
        customerModel.fax = customer.fax;
        customerModel.vat_charge = customer.vat_charge;
        customerModel.customer_order_no_compulsory = customer.customer_order_no_compulsory;
        customerModel.selectedCustomer = this.selectedCustomer;
        this.includeVatCharge = customer.vat_charge;
        this.model.customer_id = customer.id;
        this.model = Object.assign({}, this.model, customerModel);
        // get selected customers open order
        if (this.selectedCustomer) {
          let orderList = await this.$store.dispatch('order/GetOrder', {where: 'customer_id|'+this.model.customer_id, where_and: 'status|0'})
          this.invoiceItems = [];
          this.selectedOrder = [];
          this.allSelectedOrder = false;
          if (orderList && orderList.length > 0) {
            orderList.forEach(element => {
              if (element.orderItems.length > 0) {
                this.invoiceItems.push({...element, title: element.title, orderItems: JSON.parse(JSON.stringify(element.orderItems))})
              }
            });
          }
          this.model.total = 0.00;
          this.model.discount_percentage = 0;
          this.model.discount = 0.00;
          this.model.after_discount = 0.00;
          // this.model.vat_percentage = 0;
          this.model.vat = 0.00;
          this.model.grand_total = 0.00;
        }
      }
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return !v || (v && v.length <= sizeValue) || this.$t('formRules.lengthValidMessage', { fieldName: fieldValue, type: typeValue, size: sizeValue });
    },
    async prepareInvoice() {
      if(this.$refs.form.validate()){
        this.loading = true;
        let orderItems;
        orderItems = this.selectedOrder;
        this.model.data = orderItems;
        if(this.model.data.length > 0) {
          this.$emit('update:isMultiple', true);
          this.$emit('update:multipleOrderModel', this.model);
          this.loading = false;
          this.$emit('update:multipleOrderDialog', false);
        } else {
          this.message = "formRules.productItemAlert";
          this.alert = true;
          this.loading = false;
        }
      } else {
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    }
  }
}
</script>

<style scoped>
::v-deep .custom-table ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
</style>