<template>
  <v-dialog v-model="dialog" width="500" @click:outside="$emit('update:dialog', false);">
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        {{ $t('auxiliaryTable.confirm') }}
        <v-spacer />
        <v-btn
          icon
          @click="$emit('update:dialog', false);"
        >
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="text-body-1 mt-4">
        {{ $t('common.pleaseClickOnBelowOptionButton') }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="text-capitalize"
          outlined
          small
          @click="byWholeInvoice"
        >
          {{ $t('common.byWholeInvoice') }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-capitalize"
          small
          @click="byTheUser"
        >
          {{ $t('common.byTheUser') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  <ByWholeInvoice v-if="byWholeInvoiceDialog" :dialog.sync="byWholeInvoiceDialog" />
  </v-dialog>
</template>
<script>
import ByWholeInvoice from '@/components/ByWholeInvoice.vue';
export default {
  name: "CreditInvoiceConfirmationDialog",
  data() {
    return {
      byWholeInvoiceDialog: false,
    }
  },
  components: {
    ByWholeInvoice
  },
  props: ["dialog", "creditInvoiceType", "model"],
  methods: {
    numberFromCommas(x) {
      return x ? x.toString().replace(/,/g, '') : null;
    },
    byTheUser(){
      this.$emit('update:creditInvoiceType', 0);
      this.$emit('update:dialog', false);
      this.$emit('createCreditInvoice');
    },
    byWholeInvoice(){
      const newBalance = parseFloat(this.model.balance)
      if ((newBalance) !== parseFloat(this.model.grand_total)) {
        this.byWholeInvoiceDialog = true;
      } else {
        this.$emit('update:creditInvoiceType', 1);
        this.$emit('update:dialog', false);
        this.$emit('createCreditInvoice');
      }
    }
  }
}
</script>