<template>
  <div>
    <v-dialog
      v-model="receiptRecordsDialog"
      @click:outside="$emit('update:receiptRecordsDialog', false)"
      max-width="70%"
    >
      <ReceiptRecordTable v-if="receiptRecordsDialog" :receiptRecordsDialog.sync="dialog" />
    </v-dialog>
  </div>
</template>
<script>
import ReceiptRecordTable from "@/components/receiptRecords/Tables/ReceiptRecordTable.vue";

export default {
  name: 'ReceiptRecordsDialog',
  components: {
    ReceiptRecordTable
  },
  props: ['receiptRecordsDialog'],
  data() {
    return {
      dialog: false
    }
  },
  watch: {
    async dialog(val) {
      if (!val) {
        this.$emit('update:receiptRecordsDialog', false)
      }
    },
    receiptRecordsDialog(val) {
      if (!val) {
        this.dialog = false;
      } else {
        this.dialog = true;
      }
    }
  },
}
</script>